import sum from 'lodash/sum';

export const types = {
  SET_SUBJECTS: 'SET_SUBJECTS',
  SET_SELECTED_SUBJECT: 'SET_SELECTED_SUBJECT',
};

export const initialState = {
  subjects: [],
  selectedSubject: null,
  units: [],
};

export default {
  namespaced: true,
  state: { ...initialState },
  getters: {
    subjects: (state) => state.subjects,
    selectedSubject: (state) => state.selectedSubject,
    units: (state) => {
      if (state.selectedSubject == null) return [];
      return state.selectedSubject.units;
    },
    answeredRate: (state) => {
      const answeredRates = state.subjects.filter((x) => x.answered_rate > 0).map((x) => x.answered_rate);
      if (answeredRates.length === 0) return 0;
      return Math.round(sum(answeredRates) / answeredRates.length, 2);
    },
    getSubjectAnsweredRate:
      ({ selectedSubject }) =>
      () => {
        if (selectedSubject == null) return 0;
        return selectedSubject.answered_rate !== undefined ? Math.round(selectedSubject.answered_rate) : 0;
      },
    getSubjectUnAnsweredRate:
      ({ selectedSubject }) =>
      () => {
        if (selectedSubject == null) return 100;
        return selectedSubject.unanswered_rate !== undefined ? Math.round(selectedSubject.unanswered_rate) : 100;
      },
    getSubjectWeakRate:
      ({ selectedSubject }) =>
      () => {
        if (selectedSubject == null) return 0;
        return selectedSubject.weak_rate !== undefined ? Math.round(selectedSubject.weak_rate) : 0;
      },
    getAnsweredRateByUnitId:
      ({ selectedSubject }) =>
      (unitId) => {
        const units = selectedSubject ? selectedSubject.units : [];
        if (units.length === 0) return 0;
        const unit = units.find((x) => x.unit_id === unitId);
        if (unit === undefined) return 0;
        return unit.answered_rate !== undefined ? Math.round(unit.answered_rate) : 0;
      },
    getUnAnsweredRateByUnitId:
      ({ selectedSubject }) =>
      (unitId) => {
        const units = selectedSubject ? selectedSubject.units : [];
        if (units.length === 0) return 100;
        const unit = units.find((x) => x.unit_id === unitId);
        if (unit === undefined) return 100;
        return unit.unanswered_rate !== undefined ? Math.round(unit.unanswered_rate) : 100;
      },
    getWeakRateByUnitId:
      ({ selectedSubject }) =>
      (unitId) => {
        const units = selectedSubject ? selectedSubject.units : [];
        if (units.length === 0) return 0;
        const unit = units.find((x) => x.unit_id === unitId);
        if (unit === undefined) return 0;
        return unit.weak_rate !== undefined ? Math.round(unit.weak_rate) : 0;
      },
  },
  actions: {
    setSubjects({ commit }, { subjects }) {
      commit(types.SET_SUBJECTS, { subjects });
    },
    setSelectedSubject({ commit }, { selectedSubject }) {
      commit(types.SET_SELECTED_SUBJECT, { selectedSubject });
    },
  },
  mutations: {
    [types.SET_SUBJECTS](state, { subjects }) {
      state.subjects = [...subjects];
    },
    [types.SET_SELECTED_SUBJECT](state, { selectedSubject }) {
      state.selectedSubject = selectedSubject;
    },
  },
};
